<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12 class="overlay">
        <v-card outlined class="primary--border">
          <v-card-title class="title">
            Exam Routine
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card outlined>
            <v-card-title class="title pb-0">
              <v-flex xs5>
                <v-select
                  :items="exams"
                  class="pa-0"
                  label="Exam"
                  v-model="exam_id"
                  outlined
                  dense
                />
              </v-flex>
              <v-flex xs3>
                <v-select
                  :loading="loadingGrade"
                  :disabled="!grades.length"
                  :items="grades"
                  class="pa-0"
                  label="Grade"
                  v-model="grade"
                  outlined
                  dense
                />
              </v-flex>
            </v-card-title>
          </v-card>
          <v-data-table
            :headers="headers"
            hide-default-footer
            :options.sync="pagination"
            :items="form.items.data"
            :loading="form.loading"
          >
            <template v-slot:item="{ index, item }">
              <tr>
                <td>{{ index + form.items.meta.from }}</td>
                <td class="text-xs-left">
                  <strong>{{ item.subject.name | capitalize }}</strong> <br />
                  <small style="color:#555">
                    <span
                      style="color:red;"
                      v-if="
                        /शनिबार/.test(
                          getDateFormat(form.items.data[index].exam_date)
                        )
                      "
                    >
                      {{ getDateFormat(form.items.data[index].exam_date) }}
                    </span>
                    <span v-else>
                      {{ getDateFormat(form.items.data[index].exam_date) }}
                    </span>
                  </small>
                </td>
                <td class="text-xs-left">
                  <br />
                  <!-- :disablePreviousDate="true" -->

                  <div style="width: 150px;">
                    <v-calendar-field
                      :disabled="is_publish"
                      @onChange="updateState = true"
                      v-model="form.items.data[index].exam_date"
                      :id-val="'start_date_' + index"
                      label="Exam Date"
                    ></v-calendar-field>
                  </div>
                </td>
                <td class="text-xs-left">
                  <div style="display: flex">
                    <input
                      @focus="$event.target.select()"
                      class="marks_entry"
                      style="width: 53px;margin-right: 5px;"
                      autocomplete="off"
                      v-model="form.items.data[index].start_time"
                      :disabled="is_publish"
                      @keyup="
                        (updateState = true),
                          changeStartTime(
                            form.items.data[index].start_time,
                            index
                          )
                      "
                      v-mask="'##:##'"
                    />
                    <select
                      @change="
                        (updateState = true),
                          changeAP(
                            form.items.data[index].st_ampm,
                            index,
                            'start'
                          )
                      "
                      :items="ampm"
                      class="marks_entry"
                      style="width: 39px;padding-left: 9px;"
                      :disabled="is_publish"
                      v-model="form.items.data[index].st_ampm"
                    >
                      <option value="AM">AM</option>
                      <option value="PM">PM</option>
                    </select>
                  </div>
                </td>
                <td class="text-xs-right">
                  <div style="display: flex">
                    <input
                      @focus="$event.target.select()"
                      class="marks_entry"
                      style="width: 53px;margin-right: 5px;"
                      autocomplete="off"
                      v-model="form.items.data[index].end_time"
                      :disabled="is_publish"
                      @keyup="
                        (updateState = true),
                          changeEndTime(form.items.data[index].end_time, index)
                      "
                      v-mask="'##:##'"
                    />
                    <select
                      @change="
                        (updateState = true),
                          changeAP(form.items.data[index].et_ampm, index, 'end')
                      "
                      class="marks_entry"
                      style="width: 39px;padding-left: 9px;"
                      :disabled="is_publish"
                      v-model="form.items.data[index].et_ampm"
                    >
                      <option value="AM" selected>AM</option>
                      <option value="PM">PM</option>
                    </select>
                  </div>
                </td>
              </tr>
            </template>

            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="warning"
            >
              Your search for "{{ search }}" found no results.
            </v-alert>
          </v-data-table>
          <v-card-title v-if="form.items.data.length">
            <v-btn
              :outlined="form.is_update"
              :loading="saving"
              large
              block
              :disabled="!updateState || saving"
              v-if="
                form.items.data.length &&
                  $auth.can('routine-create') &&
                  $auth.can('routine-update') &&
                  !is_publish
              "
              :color="form.is_update ? 'warning' : 'primary'"
              @click="save"
            >
              {{ form.is_update ? "Update" : "Save" }}
            </v-btn>
          </v-card-title>
        </v-card>

        <div :class="'inner-overlay ' + (!disabled_alert ? 'dont-show' : '')">
          <p>
            <v-icon style="font-size: 50px;color:red;">warning</v-icon>
            <br />
            Update Routine Disabled. <br />
            <span style="font-size: 15px;">
              Exam has already been published therefore you cannot modify any
              previous record for this Exam / Grade.
              <a
                href=""
                @click.prevent="disabled_alert = false"
                title="I just want to see the records"
                >Close</a
              >
            </span>
          </p>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import Form from "@/library/Form";

const NepaliDate = require("nepali-date");
const nd = new NepaliDate();
import Mixins from "@/library/Mixins";

export default {
  mixins: [Mixins],

  data: () => ({
    updateState: false,
    saving: false,
    form: new Form(
      {
        exam_id: "",
        grade_id: "",
        routines: "",
        is_update: false,
      },
      "/api/exam-routine"
    ),
    search: null,
    startTimeDialog: false,
    endTimeDialog: false,
    pagination: {
      itemsPerPage: 20,
    },
    rowsPerPageItems: [5, 10, 15, 20],
    subject: "",
    exam_id: "",
    exams: [],
    grade: "",
    is_publish: false,
    disabled_alert: false,
    grades: [],
    loadingGrade: false,
    is_final: false,
    headers: [
      { text: "SN", align: "center", value: "id", width: 50, sortable: false },
      {
        text: "Subject",
        align: "left",
        value: "subject",
        sortable: false,
        width: 300,
      },
      {
        text: "Exam Date",
        align: "centre",
        value: "exam_date",
        width: 230,
        sortable: false,
      },
      {
        text: "Exam Start Time",
        align: "centre",
        value: "end_time",
        sortable: false,
        width: 150,
      },
      {
        text: "Exam End Time",
        align: "centre",
        value: "end_time",
        sortable: false,
        width: 150,
      },
    ],
    ampm: [
      { value: "AM", text: "AM" },
      { value: "PM", text: "PM" },
    ],
  }),
  computed: {
    ...mapState(["batch"]),
  },
  mounted() {
    this.getExams();
  },
  watch: {
    batch: function(value) {
      // this.get();
    },
    grade: function() {
      this.get();
    },
    exam_id: function(v) {
      this.grade = "";
      this.form.items.data = [];
      this.getGrades();
    },
  },

  methods: {
    getDateFormat(date) {
      try {
        let dateInstance = new NepaliDate(date.dateForm());
        return dateInstance.format("mmmm d, yyyy dddd");
      } catch (e) {
        return "-";
      }
    },

    get(params) {
      if (this.exam_id && this.$auth.can("routine-read") && this.grade) {
        let extraParams =
          "&batchId=" +
          this.batch.id +
          "&gradeId=" +
          this.grade +
          "&examId=" +
          this.exam_id;
        // let query = [null, undefined].includes(params) ? this.queryString() : params;
        this.form.get(null, this.queryString(extraParams)).then(({ data }) => {
          this.is_publish = data.isPublished;
          this.disabled_alert = data.isPublished;
          this.form.is_update = data.is_update;
          let d = [];

          d = this.form.items.data.map((res) => {
            if (!res.st_ampm) {
              res.st_ampm = "AM";
            }
            if (!res.et_ampm) {
              res.et_ampm = "PM";
            }
            if (!res.exam_date) {
              res.exam_date = nd.format("YYYY-MM");
            }
          });
        });
      }
    },

    save() {
      this.form.grade_id = this.grade;
      this.form.exam_id = this.exam_id;
      this.saving = true;
      this.form.routines = this.form.items.data.map((r) => {
        r.exam_date = r.exam_date.dateForm();
        return r;
      });
      this.form.fireFetch = false;
      this.form
        .store()
        .then(() => {
          this.updateState = false;
          this.form.is_update = true;
        })
        .finally(() => {
          this.saving = false;
        });
    },

    getGrades() {
      this.loadingGrade = true;
      this.$rest
        .get(
          "/api/exam-grade?rowsPerPage=25&sortBy=rank&descending=true&slim=true&examId=" +
            this.exam_id
        )
        .then(({ data }) => {
          this.grades = data.data.map((item) => {
            return { value: item.grade_id, text: item.name };
          });
        })
        .finally(() => {
          this.loadingGrade = false;
        });
    },

    getExams() {
      this.$rest.get("/api/exam").then(({ data }) => {
        this.exams = data.data.map((item) => {
          return { value: item.id, text: item.name };
        });
      });
    },

    editData(subject) {
      this.subject = subject;
      this.form.exam_date = nd.format("YYYY-MM-DD");
    },

    changeStartTime(value, index) {
      if (index == 0) {
        this.form.items.data.map((res) => {
          res.start_time = value;
        });
      }
    },

    changeEndTime(value, index) {
      if (index == 0) {
        this.form.items.data.map((res) => {
          res.end_time = value;
        });
      }
    },

    changeAP(value, index, type = "start") {
      if (index === 0) {
        switch (type) {
          case "start":
            this.form.items.data.map((res) => {
              res.st_ampm = value;
            });
            break;
          case "end":
            this.form.items.data.map((res) => {
              res.et_ampm = value;
            });
            break;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.marks_entry {
  border: 1px solid #ccc;
  font-weight: bold;
  border-radius: 3px;
  width: 142px;
  height: 25px !important;
  text-align: center !important;
}

input[disabled],
select[disabled] {
  background-color: #eee;
  color: #ccc;
  cursor: not-allowed;
}

.theme--light.v-select .v-select__selections {
  margin-top: -27px !important;
  margin-left: 8px !important;
}

input:focus {
  outline: none;
}

select:focus {
  outline: none;
}
</style>
